import { Injectable } from '@angular/core';
import {
  ApiRequestService,
  DTOCreation,
  DTOTypeConverter,
} from '@intorqa-ui/api';
import {
  DTOQuery,
  DateQueryType,
  DateRange,
  IError,
  IPresetQuery,
  QueryFilters,
} from '@intorqa-ui/core';
import { AnalysisTypes, ChartType } from '@portal/shared/enums/widget.enum';
import { WidgetFactory } from '@portal/shared/factories/widget.factory';
import { Query } from '@portal/shared/models/query-model';
import { Tag } from '@portal/shared/models/tag';
import { ECharts } from 'echarts';
import { Observable, Subject, map } from 'rxjs';
import { ITagTreeNode } from '../../shared/interfaces/tag.interface';
import { IWidget, IWidgetData } from '../../shared/interfaces/widget.interface';
import { Timeline } from '../../shared/models/timeline';
import { ISegment } from '../../widget-settings/interfaces/widget-settings.interface';
import { NavigationHistoryItem } from '../../widget-settings/models/navigation-history-item.model';
import { ResponsivePanels } from '../../widget-settings/models/responsive-panels';
import { TagAnalysis } from '../models/tag-analysis';
import { TagComparison } from '../models/tag-comparison';
import { TimeSeries } from '../models/time-series';
import { Widget } from '../models/widget';
import { Profile } from '@portal/profiles/models/profile';

@Injectable({
  providedIn: 'root',
})
export class WidgetService {
  public updateSegmentObservable = new Subject<ISegment | undefined>();
  public responsivePanels$ = new Subject<void>();
  public addWidget$ = new Subject<Widget>();
  public reload$ = new Subject<{
    widget?: Widget;
    initialState: QueryFilters;
  }>();
  public reloadWidgetDates$ = new Subject<IPresetQuery>();
  public reloadWidget$ = new Subject<{
    widget:
      | TagAnalysis
      | TagComparison
      | TimeSeries
      | Timeline
      | Profile
      | void;
    dates?: IPresetQuery;
  }>();

  public reloadChart$ = new Subject<Widget | void>();
  public updateApiKeyObservable = new Subject<string>();
  public drilldownObservable = new Subject<NavigationHistoryItem>();
  public chartResizeObservable = new Subject();
  public updateNavigationItem$ = new Subject<NavigationHistoryItem>();
  public changeWidgetDisplayObservable = new Subject<{
    params: QueryFilters;
    widget: Widget;
  }>();
  public timelineDataBoundObservable = new Subject<{
    widget: Widget;
    initialState: QueryFilters;
  }>();
  public drilldownDataBoundObservable = new Subject();
  public loaderObservable = new Subject<{
    widget: Widget | Timeline;
    showLoader: boolean;
  }>();

  private _responsivePanels: ResponsivePanels;

  public get responsivePanels(): ResponsivePanels {
    return this._responsivePanels;
  }

  public set responsivePanels(v: ResponsivePanels) {
    this._responsivePanels = v;
  }

  constructor(private apiRequestService: ApiRequestService) {}

  public getData(
    payload: {
      widget: TagComparison | TagAnalysis | TimeSeries;
      filters: DTOQuery;
    },
    state: any,
  ): Promise<IWidgetData> {
    let pageQuery = '';
    const addQueryParams = (paramName: string, paramValue: any) => {
      if (paramValue && paramName) {
        if (pageQuery) {
          pageQuery += '&';
        }
        pageQuery += `${paramName}=${paramValue}`;
      }
    };

    if (state?.where) {
      if (state.where.label === DateQueryType.Custom) {
        pageQuery += `&dateFrom=${state.where?.start}`;
        pageQuery += `&dateTo=${state.where?.end}`;
      } else {
        let preset = DateRange.findPresetByLabel(state.where.label);
        pageQuery += `&dateFrom=${DateRange.convertToEpochSec(preset?.start.toDate())}`;
        pageQuery += `&dateTo=${DateRange.convertToEpochSec(preset?.end.toDate())}`;
      }
    }

    return new Promise((resolve, reject) => {
      this.apiRequestService
        .post(
          `/widgets/data${pageQuery !== '' ? `?${pageQuery}` : ''}`,
          new DTOTypeConverter<IWidgetData>(),
          JSON.stringify(payload),
          undefined,
          'v2.0',
        )
        .then((response: IWidgetData) => {
          try {
            if (response?.series) {
              response.series = payload.widget.transformData(response.series);
              resolve(response);
            }
          } catch (error) {
            resolve(response);
          }
        })
        .catch((error: IError) => reject(error));
    });
  }

  public getDocuments(
    payload: {
      widget: Widget;
      filters: DTOQuery;
    },
    state: any,
  ): Promise<any> {
    let pageQuery = '';
    const addQueryParams = (paramName: string, paramValue: any) => {
      if (paramValue && paramName) {
        if (pageQuery) {
          pageQuery += '&';
        }
        pageQuery += `${paramName}=${paramValue}`;
      }
    };

    addQueryParams('pageSize', state?.pageSize);
    addQueryParams('page', state?.page);
    addQueryParams('dateFrom', state?.where?.start);
    addQueryParams('dateTo', state?.where?.end);
    addQueryParams('dataValue', state?.dataValue);
    return this.apiRequestService.post(
      `/widgets/docs${pageQuery !== '' ? `?${pageQuery}` : ''}`,
      new DTOTypeConverter<DTOCreation>(),
      JSON.stringify(payload),
      undefined,
      'v2.0',
    );
  }

  public createWidget(
    widget: any,
  ): Observable<TimeSeries | TagComparison | TagAnalysis | Timeline> {
    return this.apiRequestService
      .postToObservable(
        '/widgets',
        new DTOTypeConverter<any>(),
        JSON.stringify({
          ...widget,
        }),
        undefined,
        'v2.0',
      )
      .pipe(
        map((response: IWidget) => {
          return WidgetFactory.createObject(response);
        }),
      );
  }
  public deleteWidget(widgetId: string): Observable<void> {
    return this.apiRequestService.deleteToObserable(
      '/widgets/' + widgetId,
      undefined,
      'v2.0',
    );
  }

  public updateWidget(widget: Widget): Observable<Widget> {
    return this.apiRequestService
      .putToObservable(
        '/widgets/' + widget.widgetId,
        JSON.stringify({
          widget,
        }),
        undefined,
        'v2.0',
      )
      .pipe(
        map((response: IWidget) => {
          return WidgetFactory.createObject(response);
        }),
      );
  }

  public exportChart(title: string, chartInstance: ECharts): void {
    const exportImage = (dataUrl: string) => {
      const img = new Image();
      img.src = dataUrl;
      const element = document.createElement('a');
      element.setAttribute('href', img.src);
      element.setAttribute('download', title + '.png');
      element.style.display = 'none';
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);
    };

    exportImage(
      chartInstance.getDataURL({
        pixelRatio: 2,
        backgroundColor: 'white',
      }),
    );
  }

  public getDependants(tagId: string): Observable<ITagTreeNode> {
    return this.apiRequestService.getToObservable(
      `/widgets/${tagId}/dependants`,
      new DTOTypeConverter<ITagTreeNode>(),
      undefined,
      'v2.0',
    );
  }

  public getDependencies(tagId: string): Observable<ITagTreeNode> {
    return this.apiRequestService.getToObservable(
      `/widgets/${tagId}/dependencies`,
      new DTOTypeConverter<ITagTreeNode>(),
      undefined,
      'v2.0',
    );
  }

  public unlinkTag(
    unlinkId: string,
    dependencyId: string,
  ): Observable<ITagTreeNode> {
    return this.apiRequestService.putToObservable(
      `/widgets/${unlinkId}/unlink/${dependencyId}`,
      new DTOTypeConverter<ITagTreeNode>(),
      undefined,
      'v2.0',
    );
  }

  public mergeTagIntoTimeline(tag: Tag, timeline?: Timeline): Timeline {
    let extras: Query;
    if (tag) {
      extras = new Query();
      extras.query = extras.dtoToModel(tag.query);
      extras.type = tag._extras?.type;
    }
    return new Timeline(
      tag.tagId,
      tag.username,
      AnalysisTypes.TIMELINE,
      tag.name,
      tag.description,
      ChartType.TIMELINE,
      tag.ecosystemId,
      timeline?.width,
      timeline?.height,
      timeline?.x,
      timeline?.y,
      [tag.tagId],
      tag.createdDate,
      tag.sharedTag,
      extras,
      tag.categoryId,
      tag.lastTaggingTime,
      tag.updatedDate,
      tag.alertTypeId,
      timeline?.options,
      timeline?.dataPoints,
    );
  }
}
